<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              <span>部门：</span>
              <DeptCascader
                :placeholder="'请选择部门'"
                :deptList="deptList"
                v-model="parameter.deptId"
              />
            </div>
            <div class="search_center">
              <span>姓名：</span>
              <el-input
                v-model="parameter.userName"
                class="ipt_width"
                placeholder="请输入姓名"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              <span>单据编号：</span>
              <el-input
                v-model="parameter.expenseNumber"
                class="ipt_width expense__width"
                placeholder="请输入单据编号"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              <span>商务名称：</span>
              <el-select
                v-model="parameter.businessManageId"
                clearable
                filterable
                placeholder="请输入或选择商务名"
                class="ipt_width project_width"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.businessName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="search_center">
              <span>支出类型：</span>
              <Dictionary
                v-model="parameter.disbursementType"
                class="ipt_width"
                code="BUSINESS_DISBURSEMENT_TYPE"
                placeholder="请选择支出类型"
              />
            </div>
            <div class="search_center">
              <span>流程进度：</span>
              <Dictionary
                v-model="parameter.actStatus"
                class="ipt_width"
                code="BUSINESS_EXPENSE_ACT_STATUS"
                placeholder="请选择流程进度"
              />
            </div>

            <div class="search_center">
              <span>申请时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>
            <div class="search_center">
              <span>单据金额：</span>
              <NumberRangeInput v-model="numberRange" />
            </div>
            <div class="search_center">
              <span>科目选择：</span>
              <SubjectCascader
                v-model="subjectList"
                :checkStrictly="true"
                class="ipt_width"
                code="CBFYZC"
                placeholder="请选择科目"
              />
            </div>

            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button
            type="primary"
            icon="el-icon-printer"
            :loading="exportingLoading"
            v-if="permission(['ALL_DOCUMENT_EXPORT'])"
            @click="exporting"
            >导出</el-button
          >
          <el-button type="primary" @click="drawer = true">报销流程</el-button>
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <!-- <span v-if="parameter.deptId">当前项目 </span> -->

          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              width="160"
              prop="expenseNumber"
              label="单据编号"
            ></el-table-column>
            <el-table-column
              align="center"
              sortable
              width="100"
              prop="createName"
              label="姓名"
            ></el-table-column>
            <el-table-column
              align="center"
              width="110"
              sortable
              prop="deptName"
              label="部门"
            ></el-table-column>
            <!-- <el-table-column align="center" prop="postName" label="岗位"></el-table-column> -->
            <el-table-column
              align="center"
              prop="businessName"
              sortable
              min-width="320"
              label="商务名称"
              :show-overflow-tooltip="false"
            ></el-table-column>
            <el-table-column align="center" width="110" sortable prop="sumCost" label="报销费用">
              <template slot-scope="scope">
                {{ scope.row.sumCost | applyAmount }}
              </template></el-table-column
            >
            <!-- <el-table-column align="center" prop="status" label="报销进度">
              <template slot-scope="scope">
                {{ scope.row.status | dict(dictData.status) }}
              </template>
            </el-table-column> -->

            <el-table-column
              align="center"
              width="140"
              sortable
              prop="sumAllCost"
              label="总报销费用(月)"
            >
              <template slot-scope="scope">
                {{ scope.row.sumAllCost | applyAmount }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              width="150"
              sortable
              prop="sumPayedCost"
              label="已确认报销费用"
            >
              <template slot-scope="scope">
                {{ scope.row.sumPayedCost | applyAmount }}
              </template></el-table-column
            >
            <!-- <el-table-column align="center sortable " prop="applyExpenseType" label="申请类型">
              <template slot-scope="scope">
                {{
                  scope.row.applyExpenseType == 'ADVANCE_PAYMENT' ? '冲还预付款' : '正常付款申请'
                }}
              </template>
            </el-table-column> -->
            <!-- <el-table-column align="center" prop="auditDate" label="审核时间">
              <template slot-scope="scope">
                {{ scope.row.auditDate | dateFormat }}
              </template>
            </el-table-column> -->
            <el-table-column align="center" width="110" sortable prop="taskDate" label="申请日期">
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              sortable
              width="150"
              prop="expenseMode"
              label="报销方式"
            >
              <template slot-scope="scope">
                {{ scope.row.expenseMode | dict(dictData.expenseMode) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              sortable
              width="180"
              prop="applyExpenseType"
              label="付款方式"
            >
              <template slot-scope="scope">
                {{ scope.row.applyExpenseType | dict(applyExpenseType) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              sortable
              min-width="200"
              prop="disbursementType"
              label="支出类型"
            >
              <template slot-scope="scope">
                {{ scope.row.disbursementType | dict(dictData.disbursementType) }}
              </template></el-table-column
            >
            <el-table-column align="center" width="150" sortable prop="statusName" label="流程进度">
            </el-table-column>

            <el-table-column prop="title" align="center" fixed="right" width="100" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="detail(scope.row)"
                  class="text_Details_Bgc"
                  size="mini"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog width="75%" title="商务报销流程图" :visible.sync="drawer">
      <div style="padding: 5px">
        <img src="@/assets/商务报销流程图.png" alt="商务报销流程图" width="100%" />
      </div>
    </el-dialog>
    <Edit :isShow.sync="showEdit" :getTitle="getTitle" :options="form"></Edit>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('../businessCost/Edit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    SubjectCascader: () => import('@/components/subject-cascader.vue'),
    NumberRangeInput: () => import('@/components/number-range-input.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        businessManageId: null,
        userName: null,
        deptId: null,
        expenseNumber: null,
        disbursementType: null,
        actStatus: null,
        startCost: null,
        endCost: null,
        typeOne: null,
        typeTwo: null,
      },
      subjectList: [],
      numberRange: [],
      pageSize: 0,
      condition: {
        title: '',
      },
      tableData: [],
      loading: false,
      showEditDialog: false,
      dialogFormVisible: false,
      showEdit: false,
      form: {},
      projectList: [], // 商务数据
      deptList: [], // 部门 列表
      createdDate: [],
      drawer: false,
      getTitle: '',
      dictData: {
        expenseMode: [], //报销方式
        disbursementType: [], //支出类型
        status: {},
      },
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      applyExpenseType: [], //付款方式字典
      exportingLoading: false,
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },
  watch: {
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },

  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.getProjectList() /** 加载 "商务" 数据 **/
    this.getDeptList() /** 加载 "部门" 列表数据 **/
    // this.getStatus(); /** 加载 "报销进度" 字典数据 **/
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.$api.dict
      .listSysDictData('EXPENSE_MODE', true)
      .then(res => {
        this.dictData.expenseMode = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_DISBURSEMENT_TYPE', true)
      .then(res => {
        this.dictData.disbursementType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('APPLY_EXPENSE_TYPE', true)
      .then(res => {
        this.applyExpenseType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  methods: {
    exporting() {
      this.exportingLoading = true
      this.$api.businessExpense
        .businessExpenseListExport(this.parameter)
        .then(res => {
          const fileName = '商务报销明细.xlsx'
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
          }
          this.exportingLoading = false
          this.$message({
            message: '导出成功!',
            type: 'success',
          })
        })
        .catch(e => {
          this.exportingLoading = false
          console.log(e)
        })
    },
    getData() {
      this.loading = true

      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = null
        this.parameter.endDate = null
      }
      if (this.numberRange && this.numberRange.length > 0) {
        this.parameter.startCost = this.numberRange[0]
        this.parameter.endCost = this.numberRange[1]
      } else {
        this.parameter.startCost = null
        this.parameter.endCost = null
      }
      if (this.subjectList && this.subjectList.length > 0) {
        this.parameter.typeOne = this.subjectList[0]
        this.parameter.typeTwo = this.subjectList[1] ? this.subjectList[1] : null
      } else {
        this.parameter.typeOne = null
        this.parameter.typeTwo = null
      }

      this.$api.businessExpense
        .businessExpenseAllList(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == []) {
            this.tableData = res.data
          } else {
            this.tableData = res.data ? (res.data.records ? res.data.records : []) : []

            this.parameter.total = res.data ? (res.data.total ? res.data.total : 0) : 0
            this.tableData.forEach(item => {
              if (item.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT') {
                item.businessName = item.businessContent
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    detail(row) {
      row.list_s = 4
      this.getTitle = '查看报销单详情'
      this.form = {
        ...row,
      }
      this.showEdit = true
    },
    re() {
      this.form = {}
      this.getData()
      this.showEdit = false
    },
    /** 加载 "商务" 数据 **/
    getProjectList() {
      this.$api.businessExpense
        .businessNameList()
        .then(res => {
          this.projectList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.disbursementType = null
      this.parameter.userName = null
      this.parameter.businessManageId = null
      this.parameter.expenseNumber = null
      this.parameter.deptId = null
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.parameter.actStatus = null
      this.parameter.startCost = null
      this.parameter.endCost = null
      this.parameter.typeOne = null
      this.parameter.typeTwo = null
      this.subjectList = []
      this.createdDate = []
      this.numberRange = []
      this.parameter.pageNow = 1
      this.getData() /** 加载  数据 **/
    },
    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    /** 加载 "报销进度" 状态列表 **/
    // getStatus() {
    //   this.$api.dict.listSysDictData("MANAGE_STATUS", true).then((res) => {
    //     this.dictData.status = res.data;
    //   });
    // },
    dataUpdate() {
      this.$forceUpdate()
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
</style>
